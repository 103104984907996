import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = '/auth/all-permission';
function getAllPermission() {
  return storeRequest.get(`${path}`);
}
function storePermission() {
  return storeRequest.get(`/auth/store-permission`);
}
function addPermissionStoreStaff(data) {
  return storeRequest.post(`/users/add-store-staff`, data);
}
function updatePermissionStoreStaff(id, data) {
  return storeRequest.put(`/users/update-store-staff/` + id, data);
}
function getStoreStaffs() {
  return storeRequest.get(`/users/store-staffs`);
}
function getStoreStaffInfoById(id) {
  return storeRequest.get(`/users/store-staff-info?staff_id=${id}`);
}
function logoutAddStaffAccount() {
  return storeRequest.delete('/users/logout-all-staff-account');
}

function apiHistoryAccountById(id) {
  return storeRequest.get('auth/history/', +id);
}

function logoutAddStaffAccountById(id) {
  return storeRequest.delete('/users/logout-staff-account/' + id);
}
function deleteStaffAccount(id) {
  return storeRequest.delete('/users/delete-staff-account?staff_id=' + id);
}

function updateUserProfile(data) {
  return storeRequest.put('/users', data);
}
function changePasswordUser(data) {
  return storeRequest.put('/users/change-password', data);
}
export const authService = {
  getAllPermission,
  addPermissionStoreStaff,
  getStoreStaffs,
  getStoreStaffInfoById,
  logoutAddStaffAccount,
  apiHistoryAccountById,
  updatePermissionStoreStaff,
  logoutAddStaffAccountById,
  deleteStaffAccount,
  storePermission,
  updateUserProfile,
  changePasswordUser,
};
export const permissionService = {
  getStoreStaffInfoById,
};
