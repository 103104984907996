import jwt_decode from 'jwt-decode';
import STORAGE_NAME from '@/const/storage';
import storeRequest from '@/apis/request/storeRequest';
import accountRequest from '@/apis/request/accountRequest';

import { authService } from '@/apis/auth.s';
import { permissionService } from '@/apis/permission';

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  beforeUpdate() {},
  created() {
    this.setData();
  },

  methods: {
    async getPermission(id) {
      try {
        let data = (await permissionService.getStoreStaffInfoById(id)).data;
        return data;
      } catch (error) {
        //erro
      }
    },
    setData() {
      if (
        this.$route.query.access_token &&
        this.$route.query.refresh_token &&
        this.$route.params.authPage !== 'accept' &&
        this.$route.params.authPage !== 'verify'
      ) {
        let access_token = this.$route.query.access_token;
        let refresh_token = this.$route.query.refresh_token;
        let store_id = this.$route.query.store_id;
        let system_domain = this.$route.query.system_domain;
        let r_domain = this.$route.query.r_domain;

        this.$router.replace({ query: null });
        this.$store.commit('setAccountInfo', {
          storeId: store_id,
          accessToken: access_token,
          refreshToken: refresh_token,
          storeDomain: system_domain,
          rDomain: r_domain,
        });
        if (store_id) {
          window.localStorage.setItem(STORAGE_NAME.STORE_ID, store_id);
        }
        if (access_token) {
          window.localStorage.setItem(STORAGE_NAME.ACCESS_TOKEN, access_token);
          accountRequest.setToken(access_token);
          storeRequest.setToken(access_token);
        }
        if (system_domain) {
          window.localStorage.setItem(STORAGE_NAME.STORE_DOMAIN, system_domain);
        }
        if (r_domain) {
          window.localStorage.setItem('r_domain', r_domain);
        }
        if (refresh_token) {
          window.localStorage.setItem(STORAGE_NAME.REFRESH_TOKEN, refresh_token);
        }
        this.setBaseURLAxios();
      }
    },
    setBaseURLAxios() {
      let storeDomain = localStorage.getItem(STORAGE_NAME.STORE_DOMAIN);
      storeRequest.setBaseURL('https://' + storeDomain + '/api/');
    },
    // verify token
    token(token) {
      try {
        var decodedHeader = jwt_decode(token, { header: true });
        if (decodedHeader) {
          var decoded = jwt_decode(token);
          return decoded.exp > Date.now() / 1000;
        }
      } catch (error) {
        return false;
      }
    },
    // verify refresh token
    refreshToken(token) {
      try {
        var decodedHeader = jwt_decode(token, { header: true });
        if (decodedHeader) {
          var decoded = jwt_decode(token);
          return decoded.exp > Date.now() / 1000;
        }
      } catch (error) {
        return false;
      }
    },
    // renew access token
    async renewAccessToken() {
      try {
        let refreshTokenData = await authService.renewAccessToken();
        if (refreshTokenData && refreshTokenData.status === 200) {
          window.localStorage.setItem(STORAGE_NAME.ACCESS_TOKEN, refreshTokenData.data);
          accountRequest.setToken(refreshTokenData.data);
          storeRequest.setToken(refreshTokenData.data);
          // window.location.reload();
        }
      } catch (error) {
        this.logout();
      }
    },
    // func logout
    logout() {
      try {
        localStorage.clear();
        this.$router.push({ path: '/auth/login' });
        // authService.logout();
        // window.location.href = `${this.$urlAuth}auth/login?l=l`;
      } catch (error) {
        console.log(error.response);
      }
    },
    // check auth
    async auth() {
      this.isLoading = true;
      let TOKEN = localStorage.getItem(STORAGE_NAME.ACCESS_TOKEN);
      let REFRESH_TOKEN = localStorage.getItem(STORAGE_NAME.REFRESH_TOKEN);
      let verifyRefreshToken = this.refreshToken(REFRESH_TOKEN);
      if (verifyRefreshToken) {
        let verifyToken = this.token(TOKEN);
        if (verifyRefreshToken) {
          if (verifyToken) {
            this.$store.commit('setAccountInfo', {
              storeId: window.localStorage.getItem(STORAGE_NAME.STORE_ID),
              accessToken: window.localStorage.getItem(STORAGE_NAME.ACCESS_TOKEN),
              refreshToken: window.localStorage.getItem(STORAGE_NAME.REFRESH_TOKEN),
              storeDomain: window.localStorage.getItem(STORAGE_NAME.STORE_DOMAIN),
              rDomain: window.localStorage.getItem('r_domain'),
            });
            this.setBaseURLAxios();
          } else {
            window.localStorage.removeItem(STORAGE_NAME.ACCESS_TOKEN);
            await this.renewAccessToken();
            this.setBaseURLAxios();
          }
        } else {
          this.logout();
        }
      } else {
        this.logout();
      }

      this.isLoading = false;
    },
  },
};
