import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = 'helpbase/public/customHTML';

function getHomeHtml() {
  return storeRequest.get(path, {});
}

export const homeApi = {
  getHomeHtml,
};
