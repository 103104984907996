<template>
  <v-container fluid class="block_overview">
    <div class="heading">
      <div></div>
      <v-skeleton-loader type="text" v-if="!user"></v-skeleton-loader>
      <h2 class="s-mt24 s-mb12 word-break" v-else>{{ `${greeting}, ${fullName}` }}</h2>
    </div>
    <v-row>
      <v-col cols="12" md="4" sm="6" xs="6">
        <div class="block_overview h-flex">
          <span class="h-icon is-medium"
            ><v-avatar tile> <v-img :src="saleImage"></v-img> </v-avatar
          ></span>
          <div class="overview-content no-sale">
            {{ saleText }}
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4" sm="6" xs="6">
        <div class="block_overview h-flex">
          <span class="h-icon is-medium"
            ><v-avatar tile><v-img :src="orderImage"></v-img> </v-avatar
          ></span>
          <div class="overview-content no-sale">{{ orderText }}</div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" xs="12">
        <div class="flex-space-between noti-block-dashboard">
          <div class="flex" @click="redirectTo('/admin/orders')">
            <span class="s-icon is-small"><i class="mdi mdi-file-document mdi-18px"></i></span>
            <div class="m-l-sm">{{ orders }} open orders</div>
          </div>
          <span class="s-icon is-small"><i class="mdi mdi-chevron-right mdi-18px"></i></span>
        </div>
      </v-col>
      <v-col class="mt-6" md="12" xs="12">
        <div v-if="!isLoading && html" v-html="html"></div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import verifyMixin from '@/mixins/verify';
import { FETCH_PAGE_PREFERRENCE } from '@/store/actions.type';
import { homeApi } from '@/apis/home';
import { orderApi } from '@/apis/order';
import { AnalyticService } from '@/apis/analytics';
import moment from 'moment';
import STORAGE_NAME from '@/const/storage';
import { authService } from '@/apis/auth.s';

export default {
  mixins: [verifyMixin],
  components: {},
  data: () => ({
    saleText: 'No sales yet',
    orderText: 'No orders yet',
    orders: 0,
    html: '',
    isLoading: false,
    orderImage: require('@/assets/images/order.png'),
    saleImage: require('@/assets/images/sale.png'),
    items: [
      {
        src: 'https://img.shopbase.com/files/internal/media/16026706451fe6278cbc.png',
        title: 'Payment is now easier than ever!',
        description:
          'Blocked payment account and money hold are no longer a pain with LattePrint. Try our new Print-On-Demand platform to experience smooth built-in payment gateway with fast payout. Sign up to get $5 reward now.',
        redirectUrl: '/admin/orders',
      },
    ],
  }),
  async mounted() {
    //await this.$store.dispatch();
    // await this.$store.dispatch(FETCH_PAGE_PREFERRENCE);

    await this.getTodayTotalSales();
    await this.getTodayTotalOrders();
  },
  async created() {
    await this.getHtml();
    await this.checkSurvey();
    await this.countOrders();
  },
  methods: {
    async getHtml() {
      try {
        this.isLoading = true;
        const { data } = await homeApi.getHomeHtml();
        this.html = data.homeHtml;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error, 'error');
      }
    },
    async countOrders() {
      try {
        this.isLoading = true;
        const { data } = await orderApi.getCount({ fulfillmentStatus: 'unfulfilled' });
        this.orders = data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error, 'error');
      }
    },
    redirectTo(url) {
      this.$router.push({ path: url });
    },
    async getTodayTotalSales() {
      let { stores, startDate, endDate } = this.loadQueryFromCache();
      if (!startDate)
        startDate = moment()
          .tz(this.pagePreference.standardTimezone || 'America/Los_Angeles')
          .format('YYYY-MM-DD');
      let d = {
        fromTime: startDate,
        toTime: startDate,
        stores,
        timezone: this.pagePreference.standardTimezone || 'America/Los_Angeles',
      };
      let strStores = Array.isArray(d.stores) ? d?.stores.join(',') : d.stores;
      const { data } = await AnalyticService.fetchTotalSales(d.fromTime, d.toTime, strStores, d.timezone);
      if (data) {
        this.saleText = `$${data.total_sales || 0}`;
      }
    },
    async getTodayTotalOrders() {
      let { stores, startDate, endDate } = this.loadQueryFromCache();
      if (!startDate)
        startDate = moment()
          .tz(this.pagePreference.standardTimezone || 'America/Los_Angeles')
          .format('YYYY-MM-DD');
      let d = {
        fromTime: startDate,
        toTime: startDate,
        stores,
        timezone: this.pagePreference.standardTimezone || 'America/Los_Angeles',
      };
      let strStores = Array.isArray(d.stores) ? d?.stores.join(',') : d.stores;
      const { data } = await AnalyticService.fetchTotalOrders(d.fromTime, d.toTime, strStores, d?.timezone);
      if (data) {
        this.orderText = `${data.total_orders || 0} orders`;
      }
    },
    loadQueryFromCache() {
      let cacheQuery = localStorage.getItem(STORAGE_NAME.REPORT_SEARCH_QUERY);
      return cacheQuery ? JSON.parse(cacheQuery) : {};
    },
    async checkSurvey() {
      const storeDomain = localStorage.getItem('store-domain');
      const accessToken = localStorage.getItem('access-token');
      const storeId = localStorage.getItem('store-id');
      if (!storeDomain || !accessToken || !storeId) {
        this.$router.push({ path: '/auth/login' });
      } else {
        let data = await authService.checkSurvey({
          storeId,
          token: accessToken,
        });
        if (data?.status != 200) {
          localStorage.setItem('defaultSystemDomain', storeDomain);
          const storeInfo = JSON.parse(localStorage.getItem('FETCH_PAGE_PREFERRENCE'));

          if (!storeInfo?.storeDetail?.name) {
            this.$router.push({ path: '/auth/login' });
          } else {
            this.$router.push({
              name: 'auth/survey',
              query: {
                register: storeInfo.storeDetail.name,
                storeId,
                defaultSystemDomain: storeDomain,
              },
            });
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters(['user']),
    fullName: function() {
      // this.user = JSON.parse(localStorage.getItem('user') ?? '{}');
      let firstName = '';
      if (this.user && this.user.firstName) {
        firstName = this.user.firstName;
      }
      let lastName = '';
      if (this.user && this.user.lastName) {
        lastName = this.user.lastName;
      }
      return firstName + ' ' + lastName || '';
    },
    greeting: function() {
      const myDate = new Date();
      const hrs = myDate.getHours();

      let greet = '';

      if (hrs < 12) greet = 'Good Morning';
      else if (hrs >= 12 && hrs <= 17) greet = 'Good Afternoon';
      else if (hrs >= 17 && hrs <= 24) greet = 'Good Evening';

      return greet;
    },
    pagePreference() {
      return this.$store.getters.pagePreference;
    },
  },
};
</script>
<style lang="scss" scoped>
.block_overview {
  padding: 24px 0;
  font-size: 12px;
  // border-radius: 7px;
  -webkit-box-align: start;
  -mh-flex-align: start;
  align-items: flex-start;
}
.block_overview .overview-content.no-sale {
  -mh-flex-item-align: center;
  align-self: center;
}

.block_overview .h-icon {
  width: auto;
  height: auto;
  margin-right: 10px;
}
.block_overview .h-icon.mdi-36px {
  font-size: 36px;
}
.block_overview .h-icon .mdi-cash-multiple {
  color: #43a047;
}
.block_overview .h-icon .mdi-clipboard-text {
  color: #515dc8;
}
.word-break {
  word-break: break-word;
}
.selected_shop {
  width: 300px;
  max-width: 300px;
}
.h-flex {
  display: flex;
}
.notification-wrap > .col:first-child .noti-block-dashboard {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-top: 1px solid rgba(54, 62, 67, 0.11);
}
.notification-wrap > .col:last-child .noti-block-dashboard {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.noti-block-dashboard {
  background: #f5f6f7;
  border: 1px solid rgba(54, 62, 67, 0.11);
  border-top: none;
  padding: 16px;
  color: #525c64;
  font-weight: 700;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: flex;
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -moz-align-items: center;
  align-items: center;
}
.m-t-lg {
  margin-top: 32px;
}
</style>
