import accountRequest from './request/accountRequest';

function login(email, password) {
  accountRequest.setStoreId('');
  return accountRequest.post(`/auth/login`, { email, password });
}

function register(email, password, storeName, code) {
  accountRequest.setStoreId('');

  return accountRequest.post(
    `/auth/signup`,
    code ? { email, password, storeName, code } : { email, password, storeName },
  );
}

function logout() {
  accountRequest.setStoreId('');
  return accountRequest.delete(`/auth/logout`);
}

function renewAccessToken() {
  let token = localStorage.getItem('refresh-token');
  accountRequest.setRenewToken(token);
  return accountRequest.get(`/auth/renew-access-token`);
}
///api/auth/check-staff-available
function checkStaffAvalable(access_token, invitation_token) {
  accountRequest.setToken(access_token);
  return accountRequest.post(`/auth/check-staff-available `, {
    invitation_token,
  });
}
///api/auth/account-activation
function accountActivation(token) {
  accountRequest.setToken(token);
  return accountRequest.post(`/auth/account-activation`);
}

function sendForgotEmail(mail) {
  accountRequest.setStoreId('');
  return accountRequest.post(`/auth/forgot_password`, { email: mail });
}

function getToken(tokenn) {
  return accountRequest.get(`/auth/verify_reset_password_token`, { params: { token: tokenn } });
}

function resetPw(param) {
  accountRequest.setStoreId('');
  return accountRequest.post(`/auth/reset_password`, { password: param.password, token: param.token });
}

function registerV2(data) {
  accountRequest.setStoreId('');
  return accountRequest.post(`/auth/signup`, data);
}
function checkSurvey(params) {
  return accountRequest.get(`/stores/survey`, {
    headers: {
      'x-store-id': params.storeId,
      'x-access-token': params.token,
    },
  });
}
export const authService = {
  login,
  resetPw,
  getToken,
  sendForgotEmail,
  register,
  logout,
  renewAccessToken,
  checkStaffAvalable,
  accountActivation,
  registerV2,
  checkSurvey,
};
